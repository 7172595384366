import React from 'react';
import {connect} from 'react-redux';
import {Route, RouteComponentProps} from 'react-router';
import {Redirect} from 'react-router-dom';
import {PublicRoutes} from 'routes';
import {ApplicationState} from 'store/rootReducer';

export type UserRoles = 'admin' | 'staff' | 'nonAdmin';

interface Props {
  Component: React.FC<any>;
  path: string;
  exact?: boolean;
  requiredRoles?: UserRoles[];
}

interface DispatchProps {
  isAuthed?: boolean;
  isStaff?: boolean;
  isAdmin?: boolean;
  location?: any;
}

type AuthRouteProps = Props & DispatchProps;

const AuthRoute = ({
  Component,
  path,
  exact = false,
  requiredRoles,
  isAuthed,
  isStaff,
  isAdmin,
  location,
}: AuthRouteProps): JSX.Element => {
  const userRole = isAdmin ? 'admin' : isStaff ? 'staff' : 'nonAdmin';
  const userHasRequiredRole = requiredRoles?.includes(userRole) ?? true;
  const allowAccess = isAuthed && userHasRequiredRole;

  return (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps) => {
        if (!isAuthed)
          return (
            <Redirect
              to={{
                pathname: PublicRoutes.login,
              }}
            />
          );
        if (allowAccess) return <Component {...props} />;
        return (
          <Redirect
            to={{
              pathname: PublicRoutes.unauthorized,
            }}
          />
        );
      }}
    />
  );
};

export default connect((state: ApplicationState) => ({
  isAuthed: state.getIn(['authentication', 'data', 'is_authenticated']),
  isStaff: state.getIn(['authentication', 'data', 'user', 'is_staff']),
  isAdmin: state.getIn(['authentication', 'data', 'user', 'is_superuser']),
}))(AuthRoute);
