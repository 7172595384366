import React from 'react';
import styles from './Loading.module.scss';

const Loading: React.FC = () => {
  return (
    <div className={styles.Loading}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        cursor="crosshair"
        id="svg2"
        height="130.805"
        width="428.504"
        viewBox="0 0 428.5036 130.8048"
      >
        <g transform="matrix(1.33333 0 0 -1.33333 -183.456 941.453)" id="g10">
          <g id="g20">
            <g
              // clipPath="url(#clipPath26)"
              id="g22"
            >
              {/* Letrus */}
              <g className={styles.logo_letters_dark} fill="#edeeef">
                <g transform="translate(146.51 706.09)" id="g88">
                  <path
                    d="m 0,0 h 8.789 v -66.598 h 42.294 v -14.999 h -60 V -8.76 C -8.917,-4.08 -4.968,0 0,0"
                    id="path90"
                  />
                </g>
                <g transform="translate(221.376 663.131)" id="g92">
                  <path
                    d="M 0,0 H 23.879 C 22.919001,8.399 18.959,12.719 12.119,12.719 5.279,12.719 1.2,8.399 0,0 m 12.119,-40.078 c -19.199,0.599 -28.799,11.759 -28.799,33.358 0,21.599 9.72,32.399 29.039,32.519 20.16,0 29.639,-12.36 28.439,-37.198 H -0.24 c 0.599,-9.72 4.8,-14.64 12.599,-14.64 3.72,0 7.2,1.56 10.32,4.56 1.44,1.559 3.24,2.28 5.4,2.28 h 11.759 c -3.48,-13.44 -12.119,-20.879 -27.719,-20.879"
                    id="path94"
                  />
                </g>
                <g transform="translate(287.253 676.57)" id="g96">
                  <path
                    d="m 0,0 h -1.68 v -35.159 c 0,-2.64 1.56,-4.079 4.68,-4.079 h 4.92 v -12.12 c -5.16,-0.48 -10.8,-0.48 -16.799,0.12 -6,0.599 -8.88,4.32 -8.76,11.399 v 69.358 h 8.28 c 4.2,0 7.679,-3.84 7.679,-8.04 v -10.08 h 9.6 V 7.92 C 7.92,3.359 4.44,0 0,0"
                    id="path98"
                  />
                </g>
                <g transform="translate(301.894 657.251)" id="g100">
                  <path
                    d="m 0,0 c 0.479,20.519 11.399,30.719 32.639,30.719 h 2.279 v -16.56 h -5.399 c -8.88,0 -13.32,-4.68 -13.32,-13.919 V -32.758999 H 0 Z"
                    id="path102"
                  />
                </g>
                <g transform="translate(397.651 649.812)" id="g104">
                  <path
                    d="m -0.750013,0 c 0,-10.92 -2.76,-18.12 -8.399,-21.6 -5.64,-3.239 -11.88,-4.92 -18.72,-4.92 -6.6,0 -12.48,1.8 -17.999,5.401 -5.52,3.479 -8.279,10.439 -8.279,21.119 v 38.158 h 15.959 V -3.24 c 0,-7.08 3.599,-10.56 10.799,-10.56 7.2,0 10.8,3.48 10.8,10.56 v 33.478 c -0.12,5.281 2.4,7.92 7.68,7.92 h 8.159 z"
                    id="path106"
                  />
                </g>
                <g transform="translate(432.33 636.012)" id="g108">
                  <path
                    d="m 0,0 c 6.72,0 10.079,1.92 10.079,6 0,3.239 -5.519,5.88 -16.439,7.8 -14.039,2.519 -21.239,8.759 -21.359,18.719 0,13.679 10.799,20.159 25.319,20.399 8.16,0 14.639,-1.8 19.439,-5.4 4.68,-3.72 7.2,-8.879 7.56,-15.479 H 14.52 c -3.72,0.119 -6.121,1.44 -7.081,3.959 -1.08,2.761 -4.319,4.32 -9.599,4.32 -6.239,0 -9.36,-1.679 -9.36,-4.92 0,-3.24 4.08,-5.759 12.36,-7.319 10.8,-2.16 17.879,-4.92 21.359,-8.16 2.999,-3.12 4.44,-6.96 4.44,-11.279 0,-15.6 -12.24,-21.36 -29.039,-21.36 -8.88,0 -15.599,1.92 -19.919,5.64 -4.8,3.601 -7.2,9.12 -7.2,16.439 H -13.2 C -12.84,2.4 -7.8,0 0,0"
                    id="path110"
                  />
                </g>
              </g>

              {/* Bottom bars */}
              <g className={styles.logoBars}>
                <path
                  d="m 197.972,607.986 h -20 v 8 h 20 z"
                  id="bar1"
                  fill="#02c6bf"
                />
                <path
                  d="m 177.782,607.986 h -20 v 8 h 20 z"
                  id="bar2"
                  fill="#3e99f3"
                />
                <path
                  d="m 157.592,607.986 h -20 v 8 h 20 z"
                  id="bar3"
                  fill="#3f66df"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Loading;
