import {all, takeLatest} from 'redux-saga/effects';
import {
  AuthenticationTypes,
  loginUser,
  logoutUser,
} from './reducers/authentication';
import {fetchMarkers, MarkersTypes} from './reducers/markers';

export default function* rootSaga() {
  yield all([
    takeLatest(AuthenticationTypes.LOGIN_REQUEST, loginUser),
    takeLatest(AuthenticationTypes.LOGOUT_REQUEST, logoutUser),
    takeLatest(MarkersTypes.FETCH_REQUEST, fetchMarkers),
  ]);
}
