import {fromJS} from 'immutable';
import {MarkersState} from 'store/reducers/markers';

export const mockMarkersList = [
  {
    _id: '60386fc02b31caff1f3a90db',
    category: 'est amet',
    subcategory: 'nostrud',
    standard: 'officia',
    tipo: 'enim',
    emotional_level: 1.3527,
    complexity: 0.853,
  },
  {
    _id: '60386fc03c51f3dcaff1425e',
    category: 'cupidatat velit',
    subcategory: 'anim',
    standard: 'eu',
    tipo: 'ut',
    emotional_level: 0.3596,
    complexity: 2.2606,
  },
  {
    _id: '60386fc0f8aa8d319a08258d',
    category: 'ea deserunt',
    subcategory: 'fugiat',
    standard: 'laboris',
    tipo: 'duis',
    emotional_level: 1.8991,
    complexity: 2.0391,
  },
  {
    _id: '60386fc0c5f2879524ec7ead',
    category: 'ad nulla',
    subcategory: 'excepteur',
    standard: 'esse',
    tipo: 'reprehenderit',
    emotional_level: 2.0694,
    complexity: 1.8877,
  },
  {
    _id: '60386fc096ddbcf03edebf82',
    category: 'sit pariatur',
    subcategory: 'voluptate',
    standard: 'adipisicing',
    tipo: 'mollit',
    emotional_level: 1.0906,
    complexity: 0.2562,
  },
  {
    _id: '60386fc07036566b1f4023ca',
    category: 'veniam culpa',
    subcategory: 'pariatur',
    standard: 'aliquip',
    tipo: 'fugiat',
    emotional_level: 1.1756,
    complexity: 1.2812,
  },
  {
    _id: '60386fc08d2d64f8279a41dd',
    category: 'sunt aute',
    subcategory: 'incididunt',
    standard: 'dolor',
    tipo: 'amet',
    emotional_level: 1.418,
    complexity: 2.6897,
  },
];

export const mockedLoginSuccessData: MarkersState = fromJS({
  loading: false,
  error: false,
  data: fromJS(mockMarkersList),
});

export const mockedLoginFailureData: MarkersState = fromJS({
  loading: false,
  error: true,
  data: fromJS([]),
});
