import Login from 'containers/Login';
import MarkersList from 'containers/MarkersList';
import PageNotFound from 'containers/PageNotFound';
import Restricted from 'containers/Restricted';
import Unauthorized from 'containers/Unauthorized';
import React from 'react';
import {Route, Switch} from 'react-router-dom';
import AuthRoute from 'utils/AuthRoute';
import NoAuthRoute from 'utils/NoAuthRoute';
import ScrollToTop from 'utils/ScrollToTop';

export enum AuthRoutes {
  dashboard = '/',
  restrictedPage = '/restricted',
}

export enum PublicRoutes {
  unauthorized = '/unauthorized',
  notFound = '/not-found',
  login = '/login',
}

const routes = (
  <>
    <ScrollToTop />
    <Switch>
      <AuthRoute
        exact
        path={AuthRoutes.dashboard}
        Component={MarkersList}
        requiredRoles={['admin']}
      />
      <AuthRoute
        exact
        path={AuthRoutes.restrictedPage}
        Component={Restricted}
        requiredRoles={['admin']}
      />
      <Route path={PublicRoutes.unauthorized} component={Unauthorized} />
      <Route path={PublicRoutes.notFound} component={PageNotFound} />
      <Route path={PublicRoutes.login} component={Login} />
      <NoAuthRoute />
    </Switch>
  </>
);

export default routes;
