import {ReactComponent as Illustration} from 'images/illustration_01.svg';
import {ReactComponent as Logo} from 'images/logoLetrus.svg';
import {NewButton} from 'letrus-ui';
import React from 'react';
import {useForm} from 'react-hook-form';
import {connect} from 'react-redux';
import {
  getAuthenticationError,
  LoginActionProps,
  loginUserRequest,
} from 'store/reducers/authentication';
import {ApplicationState} from 'store/rootReducer';
import styles from './Login.module.scss';

interface DispatchProps {
  loginUserRequest(data: LoginActionProps): void;
  location?: any;
  authenticationError: boolean;
}

interface StateProps {
  showRestorePassword: boolean;
}

interface FormProps {
  username: string;
  password: string;
}

type Props = DispatchProps & StateProps;

const Login: React.FC<Props> = ({
  loginUserRequest,
  location,
  authenticationError,
}) => {
  const {
    register,
    handleSubmit,
    errors,
    formState,
    watch: watchAuthForm,
  } = useForm<FormProps>({
    mode: 'onChange',
  });

  const onSubmit = (data: FormProps) => {
    const nextRoute = location?.state?.requestedPath || '/';

    loginUserRequest({
      username: data.username,
      password: data.password,
      nextRoute,
    });
  };

  return (
    <div className={styles.container}>
      <main className={styles.mainContent}>
        <div className={styles.firstContent}>
          <div className={styles.card}>
            <h1 className={styles.title}>
              Boas-vindas ao Text Markers da Letrus!
            </h1>
            <figure className={styles.illustration}>
              <Illustration />
            </figure>
          </div>
        </div>

        <div className={styles.secondContent}>
          <div className={styles.card}>
            <div className={styles.cardHeader}>Text Markers</div>
            <>
              <div className={styles.formContainer}>
                <Logo className={styles.logo} />

                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className={styles.form}
                  data-testid="login-form"
                >
                  <span className={styles.formBlock}>
                    <label htmlFor="username">Usuário</label>
                    <input
                      type="text"
                      name="username"
                      id="username"
                      data-testid="username"
                      aria-label="username"
                      placeholder="Usuário"
                      ref={register({required: true})}
                      className={errors.username && styles.error}
                    />
                    {errors.username && (
                      <span className={styles.errorText}>
                        Esse campo não pode ficar em branco.
                      </span>
                    )}
                  </span>

                  <span className={styles.formBlock}>
                    <label htmlFor="password">Senha</label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      data-testid="password"
                      aria-label="password"
                      placeholder="Senha"
                      ref={register({required: true})}
                      className={errors.password && styles.error}
                    />
                    {errors.password && (
                      <span className={styles.errorText}>
                        Esse campo não pode ficar em branco.
                      </span>
                    )}

                    {authenticationError && formState.isSubmitted ? (
                      <span className={styles.errorText}>
                        Usuário do Text Markers e/ou senha inválidos.
                        <br />
                        Por favor, verifique e tente novamente.
                      </span>
                    ) : null}
                  </span>

                  <NewButton
                    userRole="teacher"
                    type="submit"
                    disabled={
                      !watchAuthForm().username && !watchAuthForm().password
                    }
                  >
                    Entrar
                  </NewButton>
                </form>
              </div>
            </>
          </div>
        </div>
      </main>
    </div>
  );
};

export default connect(
  (state: ApplicationState) => ({
    authenticationError: getAuthenticationError(state),
  }),
  {
    loginUserRequest,
  },
)(Login);
