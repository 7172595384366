import {mockMarkersList} from 'store/mocks/__mocks__/markersList';

export interface MarkerParams {
  page?: number;
  size?: number;
  category?: string;
}

export const fetchMarkersService = (params?: MarkerParams) =>
  Promise.resolve({data: mockMarkersList});
