import Loading from 'components/Loading';
import PageWrapper from 'components/PageWrapper';
import dayjs from 'dayjs';
import {List} from 'immutable';
import {NewButton, Pagination, Search} from 'letrus-ui';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {
  fetchMarkersRequest,
  getIsLoadingMarkers,
  getMarkersCount,
  getMarkersData,
  getMarkersError,
  Marker,
} from 'store/reducers/markers';
import {ApplicationState} from 'store/rootReducer';
import styles from './MarkersList.module.scss';

interface StateProps {
  fetchMarkersRequest: typeof fetchMarkersRequest;
  markersError: boolean;
  markersCount: number;
  isLoadingMarkers: boolean;
  markersData: List<Marker>;
}

type Props = RouteComponentProps & StateProps;

const MarkersList: React.FC<Props> = ({
  fetchMarkersRequest,
  markersData,
  markersCount,
  isLoadingMarkers,
}) => {
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = markersCount ? Math.ceil(markersCount / 10) : 1;

  const history = useHistory();

  useEffect(() => {
    fetchMarkersRequest();
  }, [fetchMarkersRequest]);

  const handleChangePage = (page: any) => {
    const paged = page - 1;
    fetchMarkersRequest({page: paged});
    setCurrentPage(page);
  };

  const handleSearchChange = (event: any) => {
    const inputValue = event.target.value.toLowerCase() || '';
    setSearchText(inputValue);

    return;
  };

  return (
    <>
      <PageWrapper>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <h1>Marcadores de texto</h1>
            <div className={styles.actions}>
              <NewButton
                userRole="teacher"
                type="submit"
                text="Adicionar Texto"
                onClick={() => history.push('/criar')}
              />
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.search}>
              <Search
                id="search"
                search={{
                  name: 'search',
                  value: searchText,
                  onChange: handleSearchChange,
                }}
                searchProps={{
                  placeholder: 'Pesquisar texto',
                  searchTag: 'input',
                  required: false,
                }}
              />
            </div>
            {isLoadingMarkers ? (
              <div>{<Loading />}</div>
            ) : (
              <div className={styles.table}>
                <div className={styles.rowHeader}>
                  <div>ID</div>
                  <div>Categoria</div>
                  <div>Subcategoria</div>
                  <div>Data de criação</div>
                  <div>Complexidade</div>
                  <div>Nível emocional</div>
                </div>
                {markersData &&
                  markersData.map((item: any, index: number) => (
                    <div
                      className={styles.row}
                      key={index}
                      onClick={() => history.push(`/editar/${item.get('_id')}`)}
                    >
                      <div>{index + 1}</div>
                      <div>{item.get('category')}</div>
                      <div>{item.get('subcategory')}</div>
                      <div>
                        {dayjs(item.get('created')).format('DD/MM/YYYY')}
                      </div>
                      <div>{item.get('complexity').toFixed(1)}</div>
                      <div>{item.get('emotional_level').toFixed(1)}</div>
                    </div>
                  ))}
              </div>
            )}
            <div className={styles.pagination}>
              <Pagination
                currentPage={currentPage}
                hasNext={currentPage < totalPages}
                hasPrevious={currentPage > 1}
                totalPages={totalPages}
                onChange={handleChangePage}
              />
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default connect(
  (state: ApplicationState) => ({
    markersData: getMarkersData(state),
    markersError: getMarkersError(state),
    isLoadingMarkers: getIsLoadingMarkers(state),
    markersCount: getMarkersCount(state),
  }),
  {
    fetchMarkersRequest,
  },
)(MarkersList);
