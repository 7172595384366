import {DropdownMenu, PageWrapper as UIPageWrapper} from 'letrus-ui';
import {ItemProps} from 'letrus-ui/dist/components/DropdownMenu';
import {Menu} from 'letrus-ui/dist/components/SideBar';
import React from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {logoutUserRequest} from 'store/reducers/authentication';
import styles from './PageWrapper.module.scss';

interface PageWrapperProps {
  logoutUserRequest: () => void;
  children?: React.ReactNode;
}

const PageWrapper: React.FC<PageWrapperProps> = ({
  logoutUserRequest,
  children,
}) => {
  const history = useHistory();

  const dropdownProps: Array<ItemProps> = [
    {
      icon: 'sign-out',
      id: 2,
      onClick: () => logoutUserRequest(),
      text: 'Sair',
    },
  ];

  const dropdownMenu = (
    <DropdownMenu items={dropdownProps} title="Menu" titleColor="black" />
  );

  const menuOptions: Menu[] = [
    {
      label: 'Home',
      icon: {icon: 'home'},
      onClick: () => history.push('/dashboard'),
      active: false,
    },
    {
      label: 'Novo texto',
      icon: {icon: 'plus-square'},
      onClick: () => history.push('/criar'),
      active: false,
    },
    {
      label: 'Teste plágio',
      icon: {icon: 'vial'},
      onClick: () => history.push('/teste-plagio'),
      active: false,
    },
  ];

  return (
    <div className={styles.wrapper}>
      <UIPageWrapper
        menu={menuOptions}
        rightComponent={dropdownMenu}
        theme="dark"
      >
        <div className={styles.container}>{children}</div>
      </UIPageWrapper>
    </div>
  );
};

export default connect(null, {logoutUserRequest})(PageWrapper);
