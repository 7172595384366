import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {ApplicationState} from 'store/rootReducer';
import {fetchMarkersService, MarkerParams} from 'store/services/markersService';
import {action} from 'typesafe-actions';

// Actions Type
export enum MarkersTypes {
  FETCH_REQUEST = '@markers/FETCH_REQUEST',
  FETCH_SUCCESS = '@markers/FETCH_SUCCESS',
  FETCH_FAILURE = '@markers/FETCH_FAILURE',
}

// Data Types
export interface Marker {
  _id: number;
  title: string;
  category: string;
  subcategory: string;
  quantity: number;
  created: string;
}

// State Type
export interface MarkersState extends Map<any, any> {
  readonly data: List<ImmutableMap<Marker>>;
  readonly loading: boolean;
  readonly error: boolean;
}

export interface MarkersGetResponse {
  result: Array<Marker>;
}

// Actions
export const fetchMarkersRequest = (params?: MarkerParams) =>
  action(MarkersTypes.FETCH_REQUEST, params);

export const fetchMarkersSuccess = (data: any) =>
  action(MarkersTypes.FETCH_SUCCESS, {data});

export const fetchMarkersFailure = () => action(MarkersTypes.FETCH_FAILURE);

// Sagas
export function* fetchMarkers(action: AnyAction) {
  try {
    const response = yield call(fetchMarkersService, action.payload);
    yield put(fetchMarkersSuccess(response.data));
  } catch (error) {
    yield put(fetchMarkersFailure());
  }
}

//Initial state
export const INITIAL_STATE: MarkersState = fromJS({
  data: fromJS([]),
  error: false,
  loading: false,
});

// Selectors
export const getMarkersData = (state: ApplicationState) =>
  state.getIn(['markers', 'data']);

export const getMarkersError = (state: ApplicationState) =>
  state.getIn(['markers', 'error']);

export const getMarkersCount = (state: ApplicationState) =>
  state.getIn(['markers', 'dataCount']);

export const getIsLoadingMarkers = (state: ApplicationState) =>
  state.getIn(['markers', 'loading']);

//Reducer
const reducer: Reducer<MarkersState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MarkersTypes.FETCH_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case MarkersTypes.FETCH_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('dataCount', action.payload.data.count)
          .set('data', fromJS(action.payload.data)),
      );
    default:
      return state;
  }
};

export default reducer;
