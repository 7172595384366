import {connectRouter, RouterState} from 'connected-react-router/immutable';
import {History} from 'history';
import {Map} from 'immutable';
import {combineReducers} from 'redux-immutable';
import authenticationReducer, {
  AuthenticationState,
} from './reducers/authentication';
import markersReducer, {MarkersState} from './reducers/markers';

export interface ApplicationState extends Map<any, any> {
  readonly authentication: AuthenticationState;
  readonly markers: MarkersState;
  readonly router: RouterState;
}

const rootReducer = (history: History) =>
  combineReducers({
    authentication: authenticationReducer,
    markers: markersReducer,
    router: connectRouter(history),
  });

export default rootReducer;
